var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { pale: _vm.model.disabled } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("GenInput", {
            attrs: {
              size: 2,
              "label-size": 1,
              entity: "custom-service",
              attr: "disabled",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.disabled,
              callback: function($$v) {
                _vm.$set(_vm.model, "disabled", $$v)
              },
              expression: "model.disabled"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("GenInput", {
            attrs: {
              size: 11,
              "label-size": 1,
              entity: "custom-service",
              attr: "description",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.description,
              callback: function($$v) {
                _vm.$set(_vm.model, "description", $$v)
              },
              expression: "model.description"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { attrs: { claas: "row" } }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 6,
              "label-size": 3,
              entity: "custom-service",
              attr: "daily",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.daily,
              callback: function($$v) {
                _vm.$set(_vm.model, "daily", $$v)
              },
              expression: "model.daily"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 6,
              "label-size": 3,
              entity: "custom-service",
              attr: "monthly",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.monthly,
              callback: function($$v) {
                _vm.$set(_vm.model, "monthly", $$v)
              },
              expression: "model.monthly"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("GenInput", {
            attrs: {
              size: 10,
              "label-size": 2,
              entity: "custom-service",
              attr: "address",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.address,
              callback: function($$v) {
                _vm.$set(_vm.model, "address", $$v)
              },
              expression: "model.address"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 4,
              "label-size": 1,
              entity: "custom-service",
              attr: "latitude",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.latitude,
              callback: function($$v) {
                _vm.$set(_vm.model, "latitude", $$v)
              },
              expression: "model.latitude"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 4,
              "label-size": 1,
              entity: "custom-service",
              attr: "longitude",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.longitude,
              callback: function($$v) {
                _vm.$set(_vm.model, "longitude", $$v)
              },
              expression: "model.longitude"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 4,
              "label-size": 2,
              entity: "custom-service",
              attr: "network",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.network,
              callback: function($$v) {
                _vm.$set(_vm.model, "network", $$v)
              },
              expression: "model.network"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md6" },
        [
          _c("GenInput", {
            attrs: {
              size: 4,
              "label-size": 2,
              entity: "custom-service",
              attr: "vlan",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.vlan,
              callback: function($$v) {
                _vm.$set(_vm.model, "vlan", $$v)
              },
              expression: "model.vlan"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }