<template>
  <div>
    <div v-if="!ready">
      <SkelInput />
      <SkelInput />
      <SkelInput />
    </div>
    <div class="well" v-else>
      <DynaList :apiDomain=domain apiTopic=client :baseUrl="`/client/${uid}/service/custom`" addText="Добавить сервис" >
        <template v-slot="data">
          <CustomService :rec=data.parent :apiDomain=domain apiTopic=client :apiPath=data.parent.entity />
        </template>
      </DynaList>
    </div>
  </div>
</template>

<script>
import DynaList from "common/visual/DynaList.vue"
import SkelInput from "common/visual/SkelInput.vue"
import GenInput from "common/visual/cooper/GenInput.vue"
import CustomService from "client/corpserv/CustomService.vue"
import sysconf from "sysconf"

export default {
  
  name: "ServList",
  
  components: {DynaList, SkelInput, GenInput, CustomService},
  
  props: {
    uid: String,
  },
  
  async created()
  {
    await this.ensure("currency"),
    this.ready = true
    this.domain = sysconf.domainByUid(this.uid) || config.domain
  },
  
  data()
  {
    return {
      ready: false,
      domain: null,
    }
  },
}

</script>
