<template>
  <div class="row">
    <div class="col-md-12">
      <ContactList :uid=state.uid />
    </div>
    <div class="col-md-12">
      <ServList :uid=state.uid />
    </div>
  </div>
</template>

<script>
import ServList from "client/corpserv/ServList.vue"
import ContactList from "client/corporate/ContactList.vue"

export default {

  name: "CorpService",
  components: {ServList, ContactList},
  
  props: {
    state: Object,
  },  
  
  data()
  {
    return {
    }
  },
 
}
</script>
