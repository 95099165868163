<template>
  <div :class="{pale: model.disabled}">
          <div class="row">
            <div class="col-md-12">
              <GenInput :size=2 :label-size=1 entity="custom-service" attr=disabled v-model="model.disabled" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <GenInput :size=11 :label-size=1 entity="custom-service" attr=description v-model="model.description" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div claas="row">
            <div class="col-md-6">
              <GenInput :size=6 :label-size=3 entity="custom-service" attr=daily v-model="model.daily" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            <div class="col-md-6">
              <GenInput :size=6 :label-size=3 entity="custom-service" attr=monthly v-model="model.monthly" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <GenInput :size=10 :label-size=2 entity="custom-service" attr=address v-model="model.address" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <GenInput :size=4 :label-size=1 entity="custom-service" attr=latitude v-model="model.latitude" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            <div class="col-6">
              <GenInput :size=4 :label-size=1 entity="custom-service" attr=longitude v-model="model.longitude" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <GenInput :size=4 :label-size=2 entity="custom-service" attr=network v-model="model.network" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            <div class="col-md6">
              <GenInput :size=4 :label-size=2 entity="custom-service" attr=vlan v-model="model.vlan" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
  </div>
</template>

<script>
import GenInput from "common/visual/cooper/GenInput.vue"

export default {
  
  name: "CustomService",
  
  components: {GenInput},
  
  props: {
    rec: Object,
    apiDomain: String,
    apiTopic: String,
    apiPath: String,
  },
  
  data()
  {
    return {
      ready: false,
      model: {},
    }
  },
  
  mounted()
  {
    this.model = this.rec
  },
  
}

</script>

<style scoped>
.pale {
  opacity: 0.5;
}
</style>