var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.ready
      ? _c(
          "div",
          [
            _c("SkelInput"),
            _vm._v(" "),
            _c("SkelInput"),
            _vm._v(" "),
            _c("SkelInput")
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "well" },
          [
            _c("DynaList", {
              attrs: {
                apiDomain: _vm.domain,
                apiTopic: "client",
                baseUrl: `/client/${_vm.uid}/service/custom`,
                addText: "Добавить сервис"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(data) {
                    return [
                      _c("CustomService", {
                        attrs: {
                          rec: data.parent,
                          apiDomain: _vm.domain,
                          apiTopic: "client",
                          apiPath: data.parent.entity
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }